import axios from "axios";
// import { Message } from "ant-design-vue";
// import Cookies from "js-cookie";
import { message  } from 'ant-design-vue';

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000,
});
// request拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
    config.headers = {
      "Content-Type": "application/json; charset=utf-8", //配置请求头
    };
    // const token=Cookies.get('Access-Token')
    // if (token) {
    //   // config.params = { token: token }; //如果要求携带在参数中
    //   config.headers["Access-Token"] = token; //如果要求携带在请求头中
    // }
    return config;
    
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (success) => {   
    
    let msg = success.data.message ? success.data.message : ''
    if(success.data.status === 401){
      // message.error(msg || "尚未登录，请登录");

      // let Ar=JSON.parse(localStorage.getItem('Ar'));
      // let username = localStorage.getItem('username')
      // let user=JSON.parse(localStorage.getItem('user'));
      // localStorage.clear()
      // localStorage.setItem('username',username)
      // localStorage.setItem('Ar',JSON.stringify(Ar))
      // localStorage.setItem('user',JSON.stringify(user))
      // Cookies.remove('Access-Token')
      // bound.deleteCache()
      // setTimeout(() => {
      //   location.href = "/";
      // },500)
    }else if(success.data.status === 500){
      if(success.config.url !== "/clerk/table/reserve"){
        message.error(msg);
      }
    }
    return success
  },
  (error) => {
   return Promise.reject(error);
  }
);

export default service;
