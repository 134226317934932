
import request from "@/request/request";


// 登录方法请求
export function contactus(data) {
  return request({
    url: "/admin/website/contactus",
    method: "post",
    data: data,
  });
}
// 链接地址
export function getClientList(data) {
  return request({
    url: "/shops/passport/getClientList",
    method: "post",
    data: data,
  });
}
// 获取下载信息
export function getDownloadList(data) {
  return request({
    url: "/shops/passport/getDownloadList",
    method: "post",
    data: data,
  });
}




