<template>
  <div class="leader">
    <div class="header">
      <img :src="`${url}logoshenyu.png`" alt="" />
      <ul>
        <li
          v-for="(item, index) in modal"
          :key="item.key"
          :class="[activeIndex == index ? 'active' : '']"
          @click="clickModule(index, item.eng)"
        >
          {{ item.value }}
        </li>
      </ul>
    </div>
    <!-- <div class="lun">
         <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" style="height:50vh"><img src="@/assets/lun1.png" alt=""></div>
               <div class="swiper-slide"> <img src="@/assets/lun2.png" alt=""></div>
                <div class="swiper-slide"> <img src="@/assets/lun3.png" alt=""></div>
                 <div class="swiper-slide"> <img src="@/assets/lun1.png" alt=""></div>
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
      </div> -->
    <div class="lun">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(item, index) in picList" :key="index">
          <img :src="item.src" alt="" />
        </swiper-slide>
        <template v-slot:pagination>
          <div class="swiper-pagination"></div>
        </template>
      </swiper>
    </div>
    <div class="solve" id="solve">解决方案</div>
    <div class="lun sove">
      <swiper :options="swiperOption1" ref="mySwiperone">
        <swiper-slide v-for="(item, index) in soveList1" :key="index">
          <img :src="item.src" alt="" />
        </swiper-slide>
        <template v-slot:button-next>
          <div class="swiper-button-next" @click="swiperNext">
            <i class="right"></i>
          </div>
        </template>
        <template v-slot:button-prev>
          <div class="swiper-button-prev" @click="swiperPrev">
            <i class="left"></i>
          </div>
        </template>
      </swiper>
    </div>
    <div class="lun sove">
      <swiper :options="swiperOption1" ref="mySwipertwo">
        <swiper-slide v-for="(item, index) in soveList2" :key="index">
          <img :src="item.src" alt="" />
        </swiper-slide>
        <template v-slot:button-next>
          <div class="swiper-button-next" @click="swiperNextTwo">
            <i class="right"></i>
          </div>
        </template>
        <template v-slot:button-prev>
          <div class="swiper-button-prev" @click="swiperPrevTwo">
            <i class="left"></i>
          </div>
        </template>
      </swiper>
    </div>
    <div class="lun sove">
      <swiper :options="swiperOption1" ref="mySwiperthree">
        <swiper-slide v-for="(item, index) in soveList3" :key="index">
          <img :src="item.src" alt="" />
        </swiper-slide>
        <template v-slot:button-next>
          <div class="swiper-button-next" @click="swiperNextThree">
            <i class="right"></i>
          </div>
        </template>
        <template v-slot:button-prev>
          <div class="swiper-button-prev" @click="swiperPrevThree">
            <i class="left"></i>
          </div>
        </template>
      </swiper>
    </div>
    <div class="solve" id="product">产品&服务</div>
    <div class="product">
      <div class="content">
        <ul>
          <li v-for="(item, index) in product" :key="index">
            <img :src="item.img" alt="" />
            <div>{{ item.name }}</div>
            <div class="font">{{ item.eng }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="wish" id="us"><img :src="`${url}wish.jpg`" alt="" /></div>
    <div class="introduce">
      <img :src="`${url}introduce.png`" alt="" />
      <div class="rightContent">
        <div class="title">
          <div class="font">公司简介</div>
          <div>Company profile</div>
        </div>
        <div class="content">
          深域数字科技收银系统是河南深域数字科技公司开发的新一代saas云收银系统，公司成立于2017年，是一家集软件、研发、销售和服务为一体的研发型软件公司，
          致力为餐饮、零售、专卖店等行业提供一站式综合解决方案。
          公司成立以来，我们一直专注于商家收银管理及流量私域转化设计与研发，持续关注客户体验与市场需求，通过丰富的业务积累和实施经验，不断沉淀技术、创新产品与服务，为引领行业快速健康发展，成为行业软件领导品牌而持续努力。
        </div>
      </div>
    </div>
    <div class="business">
      <div class="content">
        <div>
          深域数字科技收银系统：涵盖餐饮、零售、专卖、休闲娱乐等场景下的收银管理，提供收银设备+收银系统+扫码设备+打印设备的一站式完整解决方案;
        </div>
        <div>
          深域数字科技会员管理系统：包含各行业商户开展会员制营销所需的各类工具：储值、积分、折扣、兑换、电子卡券、满减优惠、推荐、分销等，覆盖拓客引流、客户留存、促活唤醒、消费复购的完整营销链条；
          <div>
            深域数字科技线上商城：通过线上商城和门店收银系统的融合，满足线上线下的营销互通，帮助商家快速建立私域流量体系，实现快速倍增；
          </div>
          多元智能支付：融合支付宝、微信、云闪付等主流支付方式，与收银软件深度融合，实现支付即会员、支付即营销等一系列跟支付相关的营销方案，用支付创造价值；
        </div>
      </div>
      <div class="img">
        <img :src="`${url}business.png`" alt="" />
        <div class="zhe">
          <div>主营业务</div>
          <div>Main business</div>
        </div>
      </div>
    </div>
    <div class="introduce type">
      <img :src="`${url}us.png`" alt="" />
      <div class="rightContent">
        <div class="title">
          <div class="font">联系我们</div>
          <div>contact us</div>
        </div>
        <div class="content">
          <!-- <div>联系电话：4008-256-257</div> -->
          <div class="wei_prev_text">手机号：15736738757</div>
          <!-- <div>微信号：15736738757</div> -->
          <img :src="`${url}wei.png`" alt="" class="wei" />
        </div>
      </div>
    </div>
    <div class="solve" id="down">下载中心</div>
    <div class="down">
      <img :src="`${url}dian.png`" alt="" />
      <div class="name">
        <div>深域数字科技收银系统</div>
        <div>为门店管理者、收银员和导购员开发的收银软件。百万商家的共同选择！</div>
      </div>
    </div>
    <ul class="zhong">
      <li v-for="(item, index) in downList" :key="index">
        <div class="title">
          <img :src="item.logoImage" alt="" />
          <div class="right">
            <div class="way">{{ item.clientName }}</div>
            <div class="name">{{ item.versionNumber }}</div>
          </div>
        </div>
        <div style="height: 9vh">
          <div class="content">
            <div class="jiao">{{ item.ban }}</div>
            <div class="ban">{{ item.ban }}</div>
            <div class="bottom">
              <div class="lian">
                {{ item.downloadUrl && item.downloadUrl.substring(0, 30) }}
              </div>
              <div class="button">
                <div class="fu" :data-clipboard-text="item.downloadUrl" @click="docopy">
                  复制链接
                </div>
                <a class="zai" :href="item.downloadUrl">
                  <a-icon type="download" />立即下载</a
                >
              </div>
            </div>
          </div>
          <!-- <div class="content">
              <div class="jiao">安卓版</div>
              <div class="ban">安卓版</div>
              <div class="bottom">
                <div class="lian">{{item.detail}}</div>
                <div class="button">
                  <a class="fu" :data-clipboard-text="item.content" @click="docopy">复制链接</a>
                  <a class="zai" :href="item.content" >
                    <a-icon type="download" />
                    立即下载</a>
                </div>
              </div>
            </div> -->
        </div>
      </li>
    </ul>
    <div class="net">
      <div class="top">
        <img src="@/assets/net.png" alt="" />
        <div class="title">
          <div class="first">Microsoft.NET</div>
          <div class="second">Microsoft.NET Freamwork 4.7.2</div>
        </div>
      </div>
      <!--<div class="link">
        <div class="left">
          <div class="ban">WIN版</div>
          <div class="lian">
            https://dotnet.microsoft.com/en-us/download/dotnet-f......ramework/thank-you/net472-developer-pack-offline-installer
          </div>
        </div>
        <div class="right">
          <a
            class="fu"
            data-clipboard-text="https://dotnet.microsoft.com/en-us/download/dotnet-framework/thank-you/net472-developer-pack-offline-installer"
            @click="docopy"
            >复制链接</a
          >
          <a
            class="zai"
            href="https://dotnet.microsoft.com/en-us/download/dotnet-framework/thank-you/net472-developer-pack-offline-installer"
            target="_blank"
          >
            <a-icon type="download" />
            立即下载</a
          >
        </div>
      </div>
      <div class="link">
        <div class="left">
          <div class="ban">Visual C++ 2015、2017、2019 和 2022</div>
          <div class="lian">https://aka.ms/vs/17/release/vc_redist.x64.exe</div>
        </div>
        <div class="right">
          <a
            class="fu"
            data-clipboard-text="https://aka.ms/vs/17/release/vc_redist.x64.exe"
            @click="docopy"
            >复制链接</a
          >
          <a class="zai" href="https://aka.ms/vs/17/release/vc_redist.x64.exe">
            <a-icon type="download" />
            立即下载</a
          >
        </div>
      </div>
      <div class="link">
        <div class="left">
          <div class="ban">Visual C++ 2013</div>
          <div class="lian">https://aka.ms/highdpimfc2013x64enu</div>
        </div>
        <div class="right">
          <a
            class="fu"
            data-clipboard-text="https://aka.ms/highdpimfc2013x64enu"
            @click="docopy"
            >复制链接</a
          >
          <a class="zai" href="https://aka.ms/highdpimfc2013x64enu">
            <a-icon type="download" />
            立即下载</a
          >
        </div>
      </div>
      <div class="link">
        <div class="left">
          <div class="ban">Visual C++ 2012</div>
          <div class="lian">
            https://download.microsoft.com/download/1/6/B/16B06F60-3B20-4FF2-B699-5E9B7962F9AE/VSU_4/vcredist_x64.exe
          </div>
        </div>
        <div class="right">
          <a
            class="fu"
            data-clipboard-text="https://download.microsoft.com/download/1/6/B/16B06F60-3B20-4FF2-B699-5E9B7962F9AE/VSU_4/vcredist_x64.exe"
            @click="docopy"
            >复制链接</a
          >
          <a
            class="zai"
            href="https://download.microsoft.com/download/1/6/B/16B06F60-3B20-4FF2-B699-5E9B7962F9AE/VSU_4/vcredist_x64.exe"
          >
            <a-icon type="download" />
            立即下载</a
          >
        </div>
      </div>
      <div class="link">
        <div class="left">
          <div class="ban">Visual C++ 2010</div>
          <div class="lian">
            https://download.microsoft.com/download/1/6/5/165255E7-1014-4D0A-B094-B6A430A6BFFC/vcredist_x64.exe
          </div>
        </div>
        <div class="right">
          <a
            class="fu"
            data-clipboard-text="https://download.microsoft.com/download/1/6/5/165255E7-1014-4D0A-B094-B6A430A6BFFC/vcredist_x64.exe"
            @click="docopy"
            >复制链接</a
          >
          <a
            class="zai"
            href="https://download.microsoft.com/download/1/6/5/165255E7-1014-4D0A-B094-B6A430A6BFFC/vcredist_x64.exe"
          >
            <a-icon type="download" />
            立即下载</a
          >
        </div>
      </div>
      <div class="link">
        <div class="left">
          <div class="ban">语音播放修复包</div>
          <div class="lian">https://static.yt91.cn/clent/win7tts.rar</div>
        </div>
        <div class="right">
          <a
            class="fu"
            data-clipboard-text="https://static.yt91.cn/clent/win7tts.rar"
            @click="docopy"
            >复制链接</a
          >
          <a class="zai" href="https://static.yt91.cn/clent/win7tts.rar">
            <a-icon type="download" />
            立即下载</a
          >
        </div>
      </div>-->
      <div class="link" v-for="item in linksList" :key="item.text">
        <div class="left">
          <div class="ban">{{ item.text }}</div>
          <div class="lian">{{ decodeURI(item.url) }}</div>
        </div>
        <div class="right">
          <a class="fu" :data-clipboard-text="item.url" @click="docopy">复制链接</a>
          <a class="zai" :href="item.url">
            <a-icon type="download" />
            立即下载</a
          >
        </div>
      </div>
    </div>
    <div class="solve" id="business">商务合作</div>
    <!-- <div class="wish"><img :src="`${url}/add.png`" alt="" /></div> -->
    <ul class="join">
      <li v-for="(item, index) in joinList" :key="index">
        <img :src="item.img" alt="" />
        <div class="heng"></div>
        <div class="title">{{ item.title }}</div>
        <div class="content">{{ item.content }}</div>
      </li>
    </ul>
    <!-- <div class="solve">联系我们</div> -->
    <!-- <Connect /> -->
    <Bottom />
  </div>
</template>
<script>
// import lun1 from 'https://static.yt91.cn/guanwang/lun1.png'
// import lun2 from '@/assets/lun2.png'
// import lun3 from '@/assets/lun3.png'
// import sove1 from '@/assets/sove1.png'
// import sove2 from '@/assets/sove2.png'
// import sove3 from '@/assets/sove3.png'
// import sove4 from '@/assets/sove4.png'
// import sove5 from '@/assets/sove5.png'
// import sove6 from '@/assets/sove6.png'
// import product1 from '@/assets/product1.png'
// import product2 from '@/assets/product2.png'
// import product3 from '@/assets/product3.png'
// import product4 from '@/assets/product4.png'
// import product5 from '@/assets/product5.png'
// import product6 from '@/assets/product6.png'
// import join1 from '@/assets/join1.png'
// import join2 from '@/assets/join2.png'
// import join3 from '@/assets/join3.png'
// import join4 from '@/assets/join4.png'
import { swiper, swiperSlide } from "vue-awesome-swiper";
import Bottom from "@/components/bottom/bottom.vue";
// import Connect from "@/components/connect/connect.vue";
const url = "https://static.yt91.cn/guanwang/";
import Clipboard from "clipboard";
import { getClientList } from "@/request/api.js";
import { getDownloadList } from "@/request/api.js";
export default {
  components: {
    swiper,
    swiperSlide,
    Bottom,
    // Connect,
  },
  data() {
    return {
      url: url,
      modal: [
        { key: 1, value: "首页", eng: "#home" },
        { key: 2, value: "解决方案", eng: "#solve" },
        { key: 3, value: "产品&服务", eng: "#product" },
        { key: 4, value: "关于我们", eng: "#us" },
        { key: 5, value: "下载中心", eng: "#down" },
        { key: 6, value: "商务合作", eng: "#business" },
      ],
      activeIndex: 0,
      picList: [
        { id: 0, src: `${url}lun1.png` },
        { id: 1, src: `${url}lun2.png` },
        { id: 2, src: `${url}lun3.png` },
      ],
      soveList1: [
        { id: 0, src: `${url}sove1.png` },
        { id: 1, src: `${url}sove2.png` },
      ],
      soveList2: [
        { id: 0, src: `${url}sove3.png` },
        { id: 1, src: `${url}sove4.png` },
      ],
      soveList3: [
        { id: 0, src: `${url}sove5.png` },
        { id: 1, src: `${url}sove6.png` },
      ],
      swiperOption: {
        loop: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        speed: 1000,
        autoplay: 3000,
        // autoplay: {
        //   delay: 3000,
        //   // disableOnInteraction: false
        // },
        effect: "slide",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOption1: {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
          dynamicBullets: false,
        },
        speed: 1000,
        autoplay: false,
        effect: "slide",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      product: [
        {
          k: 1,
          name: "客户端收银系统",
          eng: "Client cashier",
          img: `${url}product1.png`,
        },
        {
          k: 1,
          name: "手机收银/点餐系统",
          eng: "Mobile cashier/Ordering system",
          img: `${url}product2.png`,
        },
        {
          k: 1,
          name: "云后台",
          eng: "Cloud background",
          img: `${url}product3.png`,
        },
        {
          k: 1,
          name: "线上商城",
          eng: "Online mall",
          img: `${url}product4.png`,
        },
        {
          k: 1,
          name: "自建外卖",
          eng: "Self built takeout",
          img: `${url}product5.png`,
        },
        {
          k: 1,
          name: "小程序直播",
          eng: "Applet live broadcast",
          img: `${url}product5.png`,
        },
      ],
      downList: [
        {
          way: "深域数字科技重餐版",
          name: "V2.0.12",
          content:
            "https://static.yt91.cn/clent/%E9%91%AB%E6%98%93%E5%88%B7%28%E4%B8%AD%E9%A4%90%E7%89%88%29_1.0.2.exe",
          detail: "https://static.yt91.cn/...",
          ban:"WIN版"
        },
        {
          way: "轻餐版",
          name: "V2.0.12",
          content:
            "https://static.yt91.cn/clent/%E9%91%AB%E6%98%93%E5%88%B7%28%E8%BD%BB%E9%A4%90%E7%89%88%29_1.0.2.exe",
          detail: "https://static.yt91.cn/...",
          ban:"WIN版"
        },
        {
          way: "零售",
          name: "V2.0.12",
          content:
            "https://static.yt91.cn/clent/%E9%91%AB%E6%98%93%E5%88%B7%28%E9%9B%B6%E5%94%AE%E7%89%88%29_1.0.2.exe",
          detail: "https://static.yt91.cn/...",
          ban:"WIN版"
        },
        {
          way: "奶茶烘焙",
          name: "V2.0.12",
          content:
            "https://static.yt91.cn/clent/%E9%91%AB%E6%98%93%E5%88%B7%28%E7%83%98%E7%84%99%E5%A5%B6%E8%8C%B6%E7%89%88%29_1.0.2.exe",
          detail: "https://static.yt91.cn/...",
          ban:"WIN版"
        },
      ],
      joinList: [
        {
          img: `${url}join1.png`,
          title: "独立的企业法人单位",
          content:
            "必须是独立的企业法人单位，具有合法的营业执照，能独立开展对外经营活动并承担责任",
        },
        {
          img: `${url}join2.png`,
          title: "一定的软件销售经验",
          content:
            "具备一定的软件销售经验，在当地同行业中具有一定的知名度和影响力，有专门的技术和业务人员负责深域数字科技产品的销售和技术运维",
        },
        {
          img: `${url}join3.png`,
          title: "认可深域数字科技的文化和理念",
          content:
            "认可深域数字科技的文化和理念，愿意遵守深域数字科技的相关规定并接受深域数字科技的监督",
        },
        {
          img: `${url}join4.png`,
          title: "良好的商业信用",
          content: "具有良好的商业信用，无不良记录或商业欺诈行为",
        },
      ],
      linksList: [
        {
          text: "顶尖上位机",
          url: "https://static.yt91.cn/clent/qudong/LINK68%20v3.282.exe",
        },
        {
          text: "大华上位机",
          url:
            "https://static.yt91.cn/clent/qudong/%E5%A4%A7%E5%8D%8E%E4%B8%8A%E4%BD%8D%E6%9C%BA%E8%BD%AF%E4%BB%B64.0.exe",
        },
        {
          text: "研科打印机驱动",
          url:
            "https://static.yt91.cn/clent/qudong/%E7%A0%94%E7%A7%91POS58Setup_20171127.exe",
        },
        {
          text: "蕊烨58打印机驱动",
          url:
            "https://static.yt91.cn/clent/qudong/%E8%8A%AF%E7%83%A858%E7%B3%BB%E5%88%97%E4%BA%A7%E5%93%81%E9%80%9A%E7%94%A8%E9%A9%B1%E5%8A%A8%28%E7%B2%BE%E7%AE%80%E7%89%88%29.exe",
        },
        {
          text: "蕊烨80打印机驱动",
          url:
            "https://static.yt91.cn/clent/qudong/%E8%8A%AF%E7%83%A880%E7%B3%BB%E5%88%97%E4%BA%A7%E5%93%81%E9%80%9A%E7%94%A8%E9%A9%B1%E5%8A%A8%28%E7%B2%BE%E7%AE%80%E7%89%88%29.exe",
        },
        {
          text: "Windows跨网段IP设置工具 斯普瑞特",
          url: "https://file.sprinter.com.cn/SimpleNetwork_20220119.zip",
        },
        {
          text: "通用设置工具 斯普瑞特",
          url: "https://file.sprinter.com.cn/SettingToolV3.58_5.3.zip",
        },
        {
          text: "斯普瑞特驱动",
          url: "https://file.sprinter.com.cn/SP-DRV2155Win.zip",
        },
      ],
    };
  },
  mounted() {
    // new Swiper ('.swiper-container', {
    //     loop: true,
    //     // 如果需要分页器
    //     // pagination: {
    //     //   el:'.swiper-pagination',
    //     //   // clickable: true,
    //     // },
    //       pagination:'.swiper-pagination',
    //     //如果需要前进后退按钮
    //     nextButton: '.swiper-button-next',
    //     prevButton: '.swiper-button-prev',
    //     //如果需要滚动条
    //     scrollbar: '.swiper-scrollbar',
    //     //如果需要自动切换海报
    //      speed: 3000,
    //     autoplay: {
    //       delay: 3000,//时间 毫秒
    //       disableOnInteraction: false,//用户操作之后是否停止自动轮播默认true
    //     },
    //   })
    this.getPlace();
    this.getDownload();
  },
  updated() {},
  computed: {},
  methods: {
    clickModule(num, selector) {
      this.activeIndex = num;
      this.$el.querySelector(selector).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐。默认值
      });
    },
    swiperNext() {
      this.$refs.mySwiperone.swiper.slideNext();
    },
    swiperPrev() {
      this.$refs.mySwiperone.swiper.slidePrev();
    },
    swiperNextTwo() {
      this.$refs.mySwipertwo.swiper.slideNext();
    },
    swiperPrevTwo() {
      this.$refs.mySwipertwo.swiper.slidePrev();
    },
    swiperNextThree() {
      this.$refs.mySwiperthree.swiper.slideNext();
    },
    swiperPrevThree() {
      this.$refs.mySwiperthree.swiper.slidePrev();
    },
    docopy() {
      var clipboard = new Clipboard(".fu");
      clipboard.on("success", (e) => {
        this.$message.success("复制成功");
        console.log("复制成功", e);
        //  释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        this.$message.error("复制失败");
        // 不支持复制
        console.log("该浏览器不支持复制", e);
        // 释放内存
        clipboard.destroy();
      });
    },
    getPlace() {
      getClientList().then((res) => {
        if (res.data.status == 200) {
          this.downList = res.data.data;
        }
      });
    },
    getDownload() {
      getDownloadList().then((res) => {
        if (res.data.status == 200) {
          this.linksList = res.data.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.leader {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8vh;
    width: 100%;
    img {
      height: 40px;

      margin-right: 2vw;
    }
    ul {
      display: flex;
      font-size: 1.8vh;
      li {
        font-family: Source Han Sans CN;
        margin-right: 2.1vw;
        font-weight: 500;
        height: 5vh;
        line-height: 5vh;
        cursor: pointer;
      }
      .active {
        color: #eb6519;
        border-bottom: 2px solid #eb6519;
      }
    }
  }
  .lun {
    width: 100%;
    /deep/.swiper-slide {
      margin-right: 0 !important;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .sove {
    margin-bottom: 2vh;
  }
  .solve {
    width: 100%;
    height: 26.7vh;
    text-align: center;
    line-height: 26.7vh;
    font-family: SourceHanSansCN-Bold;
    font-size: 3vh;
    font-weight: bold;
  }
  /deep/.swiper-button-next {
    background: white;
    opacity: 0.59;
    color: white;
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      opacity: 1;
      border: solid black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 5px;
      font-size: 10px;
    }
    .right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }
  /deep/.swiper-button-prev {
    background: white;
    opacity: 0.59;
    color: white;
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      opacity: 1;
      border: solid black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 5px;
      font-size: 10px;
    }
    .left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }
  .product {
    background: #fff;
    margin: 5vh 5vw 10vh 5vw;
    border-top: 6px solid #f8f8f8;
    .content {
      background: #f4f6f9;
      margin-top: 5vh;
      ul {
        display: flex;
        flex-wrap: wrap;
        padding: 4vh;
        li {
          width: 22vw;
          margin: 3vw;
          background: white;
          cursor: pointer;
          box-shadow: 0px 6px 18px 3px rgba(0, 0, 0, 0.1);
          img {
            width: 22vw;
            margin-bottom: 2vh;
          }
          div {
            text-align: center;
            font-size: 1.8vh;
            line-height: 3vh;
          }
          .font {
            font-size: 1.6vh;
            margin-bottom: 3vh;
          }
        }
      }
    }
  }
  .wish {
    img {
      width: 100%;
    }
  }
  .type {
    margin-bottom: 0 !important;
    margin-top: 15vh !important;
  }
  .introduce {
    display: flex;
    margin: 10vh 13vw 10vh 13vw;
    img {
      width: 50vw;
    }
    .rightContent {
      width: 24vw;
      .title {
        background: #eb6519;
        margin-top: 5vh;
        padding: 3vh 5vw 3vh;
        color: white;
        margin-left: -2.5vw;
        div {
          font-size: 2.2vh;
          line-height: 3vh;
        }
        div:nth-child(2) {
          font-size: 2vh;
        }
      }
      .content {
        margin: 2vh 2vh 0 2vh;
        line-height: 3vh;
        font-size: 1.6vh;
      }
    }
  }
  .business {
    display: flex;
    margin: 13vh 13vw 10vh 13vw;
    .content {
      width: 26.6vw;
      line-height: 3vh;
      font-size: 1.6vh;
      padding-right: 1.5vh;
    }
    .img {
      position: relative;
      img {
        width: 45.8vw;
        height: 40vh;
      }
      .zhe {
        position: absolute;
        left: 5vw;
        top: -3vh;
        height: 48vh;
        background: #eb6519;
        width: 18.4vw;
        div {
          color: #fff;
          text-align: right;
          font-size: 2vh;
          line-height: 3.5vh;
          margin-right: 1vw;
        }
        div:nth-child(1) {
          margin-top: 3vh;
          font-size: 2.2vh;
        }
      }
    }
  }
  .down {
    display: flex;
    margin: 0 19vw;
    margin-bottom: 7vh;
    img {
      width: 10vh;
      height: 10vh;
    }
    .name {
      div {
        font-size: 3vh;
        font-weight: bold;
        margin-left: 2vh;
        line-height: 5vh;
        margin-top: 1vh;
      }
      div:nth-child(2) {
        font-size: 1.6vh;
        font-weight: 400;
        line-height: 3vh;
        margin-top: 0;
        color: #333333;
      }
    }
  }
  .zhong {
    display: flex;
    margin: 0 18vw;
    flex-wrap: wrap;
    li {
      width: 30vw;
      margin-bottom: 2.7vw;
      height: 21.5vh;
      padding-left: 1vw;
      padding-top: 1.8vh;
      padding-right: 0.5vw;
      box-shadow: 2px 6px 16px 2px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      .title {
        display: flex;
        margin-bottom: 1.8vh;
        img {
          width: 8vh;
          height: 8vh;
          margin-right: 1vw;
        }
        .right {
          .way {
            font-weight: bold;
            font-size: 2.4vh;
            line-height: 3vh;
            padding: 1.4vh 0 0 0vw;
          }
          .name {
            font-size: 1.6vh;
            line-height: 2.5vh;
            color: #bebebe;
            padding: 0.6vh 0 0 0vw;
            font-weight: bold;
          }
        }
      }
      .content {
        font-size: 1.6vh;
        background: #f4f6f9;
        border-radius: 6px;
        margin-top: 1vh;
        padding-left: 0.67vh;
        position: relative;
        .jiao {
          position: absolute;
          right: 0;
          top: 0;
          background: #ede3c7;
          width: 2.4vw;
          height: 1.8vh;
          font-size: 0.9vh;
          text-align: center;
          line-height: 1.8vh;
          border-radius: 0px 6px 0px 6px;
        }
        .ban {
          font-size: 1.4vh;
          font-weight: bold;
          padding-top: 1.5vh;
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          .lian {
            font-size: 1.6vh;
            font-weight: 500;
            color: #bbbbbb;
            line-height: 3vh;
          }
          .button {
            display: flex;
            padding-bottom: 1.1vh;
            font-size: 1.5vh;
            .fu {
              width: 5.2vw;
              // padding:0 0.5vw;
              height: 3.5vh;
              border: 1px solid #eb6519;
              border-radius: 4px;
              color: #eb6519;
              line-height: 3.5vh;
              text-align: center;
              cursor: pointer;
            }
            .zai {
              width: 6.4vw;
              height: 3.5vh;
              border: 1px solid #eb6519;
              border-radius: 4px;
              background: #eb6519;
              line-height: 3.5vh;
              text-align: center;
              color: #fff;
              margin-left: 1.6vh;
              margin-right: 0.4vw;
              cursor: pointer;
            }
          }
        }
      }
      // .download{
      //   margin:0 1.5vw  0 1.5vw;
      //   height:3.5vh;
      //   line-height: 3.5vh;
      //   font-size: 1.6vh;
      //   border: 1px solid #EB6519;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   background: #fff;
      //   border-radius: 4px;
      //   margin-bottom: 2vh;
      //   cursor: pointer;
      //   position: relative;
      //   &:hover{
      //     background: #FFF0E9;
      //     .tip{
      //       display: block;
      //     }
      //   }
      //   .tip{
      //     width:12vw;
      //     height:auto;
      //     background: #fff;
      //     position: absolute;
      //     left: 6.5vw;
      //     top:4.7vh;
      //     text-align: center;
      //     display: none;
      //     box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.17);
      //     div{
      //       margin: 1.7vh 1.7vh 1.7vh 1.7vh;
      //       background: #F7F8FA;
      //       padding-bottom: 1.7vh;
      //       height:auto;
      //       div{
      //         font-weight: bold;
      //         font-size: 1.6vh;
      //         text-align: left !important;
      //         line-height: 2.5vh;
      //         height:2.5vh;
      //         background: #F7F8FA;
      //         padding-left: 1vh;
      //         padding-top: 0.5vh;
      //       }
      //       img{
      //         width:7.8vw;
      //         height:7.8vw;
      //         margin: 0vh 1vh 0 1vh;
      //         background: red;
      //       }
      //     }
      //     .triangle{
      //       width:0;
      //       height:0;
      //       border-top: 6px solid transparent;
      //       border-left: 6px solid transparent;
      //       border-right: 6px solid transparent;
      //       border-bottom: 6px solid #fff;
      //       padding: 0;
      //       position: absolute;
      //       left: 4.8vw;
      //       top:-3vh;
      //       }
      //   }
      //   .total{
      //     display: flex;
      //     img{
      //       width:2vh;
      //       height:2vh;
      //       margin-top: 0.5vh;
      //       margin-right:0.8vh;
      //     }
      //   }
      //   div{
      //     height:3.5vh;
      //     line-height: 3.5vh;
      //   }
      // }
    }
    li:nth-child(odd) {
      margin-right: 2.1vw;
      margin-bottom: 2.1vw;
    }
  }
  .net {
    margin: 0 18vw;
    box-shadow: 2px 6px 16px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding-bottom: 2.8vh;
    .top {
      display: flex;
      padding-left: 1vw;
      padding-top: 1.8vh;
      img {
        width: 8vh;
        height: 8vh;
      }
      .title {
        margin-left: 1.1vw;
        .first {
          font-weight: bold;
          font-size: 2.4vh;
          margin-top: 1.4vh;
          height: 2vh;
          line-height: 2vh;
          color: #333333;
        }
        .second {
          font-weight: bold;
          font-size: 1.6vh;
          margin-top: 1.2vh;
          color: #bebebe;
        }
      }
    }
    .link {
      margin-left: 1vw;
      background: #f4f6f9;
      margin-top: 2.4vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 7.8vh;
      padding-left: 0.67vh;
      margin-right: 0.5vw;
      border-radius: 10px;
      word-break: break-all;
      .left {
        flex: 1;
      }
      .ban {
        font-size: 1.4vh;
        padding-top: 1.5vh;
        font-weight: bold;
      }
      .lian {
        font-size: 1.6vh;
        font-weight: 500;
        color: #bbbbbb;
        // line-height: 3vh;
      }
      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 7.5vh;
        font-size: 1.5vh;
        text-wrap: nowrap;
        .fu {
          min-width: 5.2vw;
          height: 3.5vh;
          border: 1px solid #eb6519;
          border-radius: 4px;
          color: #eb6519;
          line-height: 3.5vh;
          text-align: center;
          cursor: pointer;
        }
        .zai {
          min-width: 6.4vw;
          height: 3.5vh;
          border: 1px solid #eb6519;
          border-radius: 4px;
          background: #eb6519;
          line-height: 3.5vh;
          text-align: center;
          color: #fff;
          margin-left: 1.6vh;
          margin-right: 0.4vw;
          cursor: pointer;
        }
      }
    }
  }
  .join {
    display: flex;
    background: #f4f6f9;
    padding: 0 6.5vw;
    li {
      width: 20vw;
      background: #fff;
      box-shadow: 0px 8px 12px 1px rgba(235, 101, 25, 0.19);
      margin: 7vh 3.2vw 7vh 0;
      text-align: center;
      border-radius: 8px;
      img {
        width: 10vh;
        height: 10vh;
        margin: 2.5vh 0;
      }
      .heng {
        height: 2px;
        background: #eb6519;
        margin: 0 3vh;
      }
      .title {
        font-weight: bold;
        margin: 1.5vh;
        font-size: 2vh;
      }
      .content {
        font-size: 1.6vh;
        margin: 0 3vh;
        padding-bottom: 5vh;
      }
    }
    li:nth-child(4) {
      margin-right: 0;
    }
  }
}
/deep/ .swiper-pagination-bullet {
  background: #fff;
  height: 1.5vh;
  width: 3.6vw;
  opacity: 1;
  border-radius: 8px;
}
/deep/.swiper-pagination-bullet-active {
  background-color: #eb6519;
  width: 5.7vw;
  height: 1.5vh;
}
/deep/.swiper-pagination-bullets {
  bottom: 23px !important;
}
a {
  color: rgba(0, 0, 0, 0.65);
}
.wei_prev_text{
  font-size: 1.8vh;
  margin-bottom: 0.5vh;
}
.wei {
  width: 25vh !important;
  height: 25vh !important;
  margin-left: 0vh;
}
</style>
